import React, {useState, useEffect} from "react"
import { Row, Col, Container, Card, Button } from "react-bootstrap"
import Fetch from '../helpers/fetch';
import constants from '../helpers/constants';
import { DDMMFormat } from '../helpers/dateTime';
import { isAdmin } from '../helpers/roles';
import Layout from "../components/layout";
import SEO from "../components/seo";
import "bootstrap/dist/css/bootstrap.min.css";
import MyResponsiveBar from "../components/charts/bar";
import data from "../components/charts/data";
import { navigate } from 'gatsby'
import { upperCaserRegex, upperCaser} from '../helpers/string'

const IndexPage = () => {
  const [totalSalesChartData, setTotalSalesChartData] = useState([])
  const [qtyChartData, setQtyChartData] = useState([])
  const [billChartData, setBillChartData] = useState([])
  const [openOrdersChartData, setOpenOrdersChartData] = useState([])

  const fixDatePresentation = (date) => {
    const dateArray = date.split("-");
    if (Number(dateArray[1]) < 10) {
      dateArray[1] = `0${dateArray[1]}`
    }
    return `${dateArray[0]}-${dateArray[1]}`
  }

  const getData = (params) => {
    let url=`${constants.BACKEND_URL}/orders/totalSales`
    Fetch.get(url).then(data => {
      if (data && Array.isArray(data) && data.length > 0) {
        const arrayForChart = [];
        const arrayForQty = [];
        data.forEach(item => {
          const obj = {
            date: DDMMFormat(item.date),
            total: item.total,
          }
          arrayForChart.push(obj);

          const qtyObj = {
            date: DDMMFormat(item.date),
            LS: item.ls,
            SS: item.ss,
            LP: item.lp,
            SP: item.sp,
            JS: item.js,
            JD: item.jd,
            JT: item.jt,
            VEST: item.vest_qty,
            OTHER: item.other_qty,
          }
          arrayForQty.push(qtyObj)
        })
        
        setTotalSalesChartData(arrayForChart);
        setQtyChartData(arrayForQty)
      }
    })
    
    url=`${constants.BACKEND_URL}/status-trx/getChart`
    Fetch.get(url).then(data => {
      if (data && Array.isArray(data) && data.length > 0) {
        const arrayForChart = [];
        data.forEach(item => {
          const statuses = item.statuses;
          if (statuses) {
            let active = 0;
            let waived = 0;
            let paid = 0;
            let voidBill = 0;
            statuses.split(',').forEach(status => {
              switch (status) {
                case 'Active':
                  active += 1;
                  break;
                case 'Waived':
                  waived += 1;
                  break;
                case 'Paid':
                  paid += 1;
                  break;
                case 'Void':
                  voidBill += 1;
                  break;
                default:
                  break;
              }  
            })
            
            const obj = { 
              date: item.month,
              active,
              waived,
              paid,
              voidBill,
            }
            arrayForChart.push(obj)
          }
        })
        setBillChartData(arrayForChart)
      }
    })

    url=`${constants.BACKEND_URL}/orders/openOrders`
    Fetch.get(url).then(data => {
      if (data && Array.isArray(data) && data.length > 0) {
        const arrayForChart = [];
        data.forEach(item => {
            const obj = { 
              date: item.date ? fixDatePresentation(item.date): '',
              open: item.open,
              completed: item.completed
            }
            arrayForChart.push(obj)
        })
        setOpenOrdersChartData(arrayForChart)
      }
    })
  }
  

  useEffect(() => {
    getData();
    return () => {
      
    }
  }, [])

  const handleManufacturerBillClick = (data, event) => {
    navigate('/reports/bill', {
      state: {
        startDate: new Date(`${data.indexValue}-01`),
        endDate: new Date(`${data.indexValue}-31`),
        billStatusFilter: data.id
      }
    })
  }
  
  const handleOpenOrdersChartClick = (data, event) => {
    navigate('/reports/consultants/sales', {
      state: {
        month: data.indexValue,
        status: data.id.replace(upperCaserRegex, upperCaser)
      }
    })
  }

  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
      <Container className="text-center" fluid>
        <Row>
          <Col className="text-center mb-2">
            <h5>Dashboard</h5>
          </Col>
        </Row>
        <Row className="mb-4">
          {isAdmin() && 
            <>
            <Col sm={12} md={12} lg={6}>
              <Card>
                <Card.Body>
                  <Card.Title>Sales - Last 7 Days</Card.Title>
                  <Card.Text >
                  </Card.Text>
                  <div style={{'height':'300px', 'width':'100%'}}>
                    <MyResponsiveBar data={totalSalesChartData} keys={['total']} yAxisLegend="Sales ($)"></MyResponsiveBar>
                  </div>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <Card>
                <Card.Body>
                  <Card.Title>Qty Sold - Last 7 days</Card.Title>
                  <Card.Text >
                  </Card.Text>
                  <div style={{'height':'300px', 'width':'100%'}}>
                    <MyResponsiveBar 
                      data={qtyChartData} 
                      keys={['LS', 'SS', 'LP','SP','JS','JD', 'JT', 'VEST', 'OTHER']}
                      yAxisLegend="Qty (pcs)"
                    ></MyResponsiveBar>
                  </div>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </Col>
            </>
          }
        </Row>
        <Row className="mb-4">
          {isAdmin() && 
            <>
              <Col sm={12} md={12} lg={6}>
                <Card>
                  <Card.Body>
                    <Card.Title>Supplier Bills</Card.Title>
                    <Card.Text >
                    </Card.Text>
                    <div style={{'height':'300px', 'width':'100%'}}>
                      <MyResponsiveBar data={billChartData} keys={['active', 'waived', 'paid', 'voidBill']} yAxisLegend="#Bills" onClick={handleManufacturerBillClick}></MyResponsiveBar>
                    </div>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <Card>
                  <Card.Body>
                    <Card.Title>Completed And Open Orders</Card.Title>
                    <Card.Text >
                    </Card.Text>
                    <div style={{'height':'300px', 'width':'100%'}}>
                      <MyResponsiveBar data={openOrdersChartData} keys={['completed', 'open']} yAxisLegend="# Orders" onClick={handleOpenOrdersChartClick}></MyResponsiveBar>
                    </div>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </Card.Body>
                </Card>
              </Col>
            
            </>
          }
        </Row>
        {!isAdmin() && (<>Dashboard Under Construction</>)}
      </Container>
    </Layout>

  )
}

export default IndexPage
